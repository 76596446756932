import React from 'react';
import logo from './images/logo.jpeg'

function Header() {
  return (
    <div>
      
      <div className="container-fluid bg-dark px-5">
        <div className="row gx-4 d-none d-lg-flex">
          <div className="col-lg-6 text-start">
            <div className="h-100 d-inline-flex align-items-center py-3 me-4">
              <div className="btn-sm-square rounded-circle bg-primary me-2">
                <small className="fa fa-map-marker-alt text-white"></small>
              </div>
              <small>Kasturi Engineering Works, M- 60 & 61 M.I.D.C. Area, Jalgaon – 425 003</small>
            </div>
            <div className="h-100 d-inline-flex align-items-center py-3">
              <div className="btn-sm-square rounded-circle bg-primary me-2">
                <small className="fa fa-envelope-open text-white"></small>
              </div>
              <small> kew61@rediffmail.com</small>
            </div>
          </div>
          <div className="col-lg-6 text-end">
            <div className="h-100 d-inline-flex align-items-center py-3 me-4">
              <div className="btn-sm-square rounded-circle bg-primary me-2">
                <small className="fa fa-phone-alt text-white"></small>
              </div>
              <small>+919422207846 // +919422791696</small>
            </div>
            <div className="h-100 d-inline-flex align-items-center py-3">
              <div className="btn-sm-square rounded-circle bg-primary me-2">
                <small className="far fa-clock text-white"></small>
              </div>
              <small>Mon - sun : 9AM - 9PM</small>
            </div>
          </div>
        </div>
      </div>

   
      <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top p-0 px-4 px-lg-5">
        <a href="/" className="navbar-brand d-flex align-items-center">
          {/* Replace "Pipes" text with logo */}
          <img src={logo} alt="Logo" style={{ height: '50px' ,width:'130px' }} />
        </a>
        <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto py-4 py-lg-0">
            <a href="/" className="nav-item nav-link active">Home</a>
            <a href="about" className="nav-item nav-link">About</a>
            <a href="service" className="nav-item nav-link">Services</a>
            <a href="product" className="nav-item nav-link">Product</a>
            <a href="contact" className="nav-item nav-link">Contact</a>
          </div>
         
        </div>
      </nav>
    </div>
  );
}

export default Header;
