import React, { useState } from 'react';
import logo from './images/logo.jpeg'
import './Footer.css'

function Footer() {
  // State to store the email input
  const [email, setEmail] = useState('');

  // Function to handle the form submission
  const handleSubscribe = (e) => {
    e.preventDefault(); // Prevent page reload

    if (email) {
      // Example: You can send the email to an API or server here
      console.log(`Subscribed with email: ${email}`);

      // Reset the email input field
      setEmail('');

      // You can display a success message to the user here
      alert('Thank you for subscribing!');
    } else {
      // If email is empty, display an error message
      alert('Please enter a valid email.');
    }
  };

  return (
    <div>
      <div className="container-fluid bg-dark text-secondary footer mt-5 py-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-3 col-md-6">
              <h5 className="text-light mb-4">Contact Us</h5>
              <p className="mb-2"><i className="fa fa-map-marker-alt me-3"></i>Kasturi Engineering Works, M- 60 & 61 M.I.D.C. Area, Jalgaon – 425 003</p>
              <p className="mb-2"><i className="fa fa-phone-alt me-3"></i>+919422207846 // +919422791696</p>

              
            </div>
            <div className="col-lg-3 col-md-6">
              <h5 className="text-light mb-4">Our Products</h5>
              <a className="btn btn-link" href="service">Plastic Moulds</a>
              <a className="btn btn-link" href="service">Household Moulds</a>
              <a className="btn btn-link" href="service">Plastic Food Container Moulds</a>
              <a className="btn btn-link" href="service">Industrial Mould</a>
            </div>
            <div className="col-lg-3 col-md-6">
              <h5 className="text-light mb-4">Quick Links</h5>
              <a className="btn btn-link" href="about">About Us</a>
              <a className="btn btn-link" href="contact">Contact Us</a>
              <a className="btn btn-link" href="service">Our Services</a>
              <a className="btn btn-link" href="contact">Home</a>
              <a className="btn btn-link" href="product">Product</a>
            </div>
            <div className="col-lg-3 col-md-6">
             
              <a className="logo">
                <img src={logo} alt="Company Logo" style={{ maxWidth: '280px' , height:'110px' }} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-4" style={{ background: '#000000' }}>
        <div className="container">
          <div className="row">
            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
              &copy; <a className="border-bottom" href="#">KASTURI ENGINEERING WORKS</a>, All Rights Reserved.
            </div>
            <div className="col-md-6 text-center text-md-end">
              Designed By Shree Digital Marketing Agency
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
