import React from 'react';
import pipe1 from './images/kast1.jpg'
import pipe2 from './images/kast2.jpg'
import pipe3 from './images/kast3.jpg'
import pipe4 from './images/kast4.jpg'
import pipe5 from './images/kast5.jpg'
import pipe6 from './images/kast6.jpg'
import pipe7 from './images/kast7.jpg'
import pipe8 from './images/kast8.jpeg'
import pipe9 from './images/swr.jpeg'

function Product() {
  return (
    <div>
   
   <div className="container-fluid page-header py-5 mb-5">
                <div className="container py-5">
                    <h1 className="display-3 text-white mb-3 animated slideInDown">Product</h1>
                    <nav aria-label="breadcrumb animated slideInDown">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a className="text-white" href="#">Home</a></li>
                            <li className="breadcrumb-item"><a className="text-white" href="#">Pages</a></li>
                            <li className="breadcrumb-item text-white active" aria-current="page">Product</li>
                        </ol>
                    </nav>
                </div>
            </div>



            <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <div className="bg-primary mb-3 mx-auto" style={{ width: '60px', height: '2px' }}></div>
            <h1 className="display-5 mb-5">Our Product</h1>
          </div>
          <div className="row mt-n2 wow fadeInUp" data-wow-delay="0.3s">
            <div className="col-12 text-center">
              <ul className="list-inline mb-5" id="portfolio-flters">
                <li className="mx-2 active" data-filter="*">All</li>
              
              </ul>
            </div>
          </div>
          <div className="row g-4 portfolio-container">
            
            {/* PVC Pipes */}
            <div className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp" data-wow-delay="0.1s">
              <div className="portfolio-inner">
                <img className="img-fluid w-100" src={pipe1} alt="" />
                <div className="text-center p-4">
                  <p className="text-primary mb-2"> Plastic Drum Mould</p>
                
                </div>
                <div className="portfolio-text text-center bg-white p-4">
                 
                  <h5 className="lh-base mb-3">Ideal for residential water systems and drainage applications.</h5>
                  <div className="d-flex justify-content-center">
                   
                  
                  </div>
                </div>
              </div>
            </div>

            {/* Copper Pipes */}
            <div className="col-lg-4 col-md-6 portfolio-item second wow fadeInUp" data-wow-delay="0.3s">
              <div className="portfolio-inner">
                <img className="img-fluid w-100" src={pipe3} alt="Copper Pipe" />
                <div className="text-center p-4">
                  <p className="text-primary mb-2">Customized Plastic Injection Mould</p>
                
                </div>
                <div className="portfolio-text text-center bg-white p-4">
                
                  <h5 className="lh-base mb-3">Corrosion-resistant and ideal for high-pressure Moulds systems.</h5>
                  <div className="d-flex justify-content-center">
                   
                  </div>
                </div>
              </div>
            </div>

            {/* Galvanized Pipes */}
            <div className="col-lg-4 col-md-6 portfolio-item third wow fadeInUp" data-wow-delay="0.5s">
              <div className="portfolio-inner">
                <img className="img-fluid w-100" src={pipe2} alt="Galvanized Pipe" />
                <div className="text-center p-4">
                  <p className="text-primary mb-2">Plastic Dustbin Moulding Die</p>
                
                </div>
                <div className="portfolio-text text-center bg-white p-4">
                 
                  <h5 className="lh-base mb-3">Perfect for outdoor plumbing and irrigation systems.</h5>
                  <div className="d-flex justify-content-center">
                    
                 
                  </div>
                </div>
              </div>
            </div>

            {/* PEX Pipes */}
            <div className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp" data-wow-delay="0.1s">
              <div className="portfolio-inner">
                <img className="img-fluid w-100" src={pipe4} alt="PEX Pipe" />
                <div className="text-center p-4">
                  <p className="text-primary mb-2">Plastic Rotational Mould</p>
                 
                </div>
                <div className="portfolio-text text-center bg-white p-4">
                
                  <h5 className="lh-base mb-3">A plastic rotational mould is used to create hollow plastic products by rotating and heating the mould.</h5>
                  <div className="d-flex justify-content-center">
                   
                  </div>
                </div>
              </div>
            </div>

            {/* CPVC Pipes */}
            <div className="col-lg-4 col-md-6 portfolio-item second wow fadeInUp" data-wow-delay="0.3s">
              <div className="portfolio-inner">
                <img className="img-fluid w-100" src={pipe5} alt="CPVC Pipe" />
                <div className="text-center p-4">
                  <p className="text-primary mb-2">Plastic Bucket Mould</p>
                 
                </div>
                <div className="portfolio-text text-center bg-white p-4">
                
                  <h5 className="lh-base mb-3">A plastic bucket mould is a tool for shaping plastic buckets through injection molding.</h5>
                  <div className="d-flex justify-content-center">
                   
                  </div>
                </div>
              </div>
            </div>

            {/* Stainless Steel Pipes */}
            <div className="col-lg-4 col-md-6 portfolio-item third wow fadeInUp" data-wow-delay="0.5s">
              <div className="portfolio-inner">
                <img className="img-fluid w-100" src={pipe6} alt="Stainless Steel Pipe" />
                <div className="text-center p-4">
                  <p className="text-primary mb-2">Plastic Square Container Mould</p>
                 
                </div>
                <div className="portfolio-text text-center bg-white p-4">
               
                  <h5 className="lh-base mb-3">A plastic square container mould is used for shaping square plastic containers through injection molding.</h5>
                  <div className="d-flex justify-content-center">
                    
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp" data-wow-delay="0.1s">
              <div className="portfolio-inner">
                <img className="img-fluid w-100" src={pipe7} alt="PEX Pipe" />
                <div className="text-center p-4">
                  <p className="text-primary mb-2">Plastic Mould</p>
                 
                </div>
                <div className="portfolio-text text-center bg-white p-4">
                
                  <h5 className="lh-base mb-3">A plastic  mould is used to create hollow plastic products by  heating the mould.</h5>
                  <div className="d-flex justify-content-center">
                   
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp" data-wow-delay="0.1s">
              <div className="portfolio-inner">
                <img className="img-fluid w-100" src={pipe8} alt="PEX Pipe" />
                <div className="text-center p-4">
                  <p className="text-primary mb-2">swr fitting</p>
                 
                </div>
                <div className="portfolio-text text-center bg-white p-4">
                
                  <h5 className="lh-base mb-3">A SWR fitting mould is used for manufacturing soil, waste, and rainwater (SWR) pipe fittings through injection molding</h5>
                  <div className="d-flex justify-content-center">
                   
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp" data-wow-delay="0.1s">
              <div className="portfolio-inner">
                <img className="img-fluid w-100" src={pipe9} alt="PEX Pipe" />
                <div className="text-center p-4">
                  <p className="text-primary mb-2">swr fitting </p>
                 
                </div>
                <div className="portfolio-text text-center bg-white p-4">
                
                  <h5 className="lh-base mb-3">A SWR fitting mould is used for manufacturing soil, waste, and rainwater (SWR) pipe fittings through injection molding.</h5>
                  <div className="d-flex justify-content-center">
                   
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>


     
    </div>
  );
}

export default Product;
