import React from 'react';
import about from './images/kasta.jpg'

function About() {
    return (
        <div>
           
           
            {/* Navbar End */}

            {/* Page Header Start */}
            <div className="container-fluid page-header py-5 mb-5">
                <div className="container py-5">
                    <h1 className="display-3 text-white mb-3 animated slideInDown">About Us</h1>
                    <nav aria-label="breadcrumb animated slideInDown">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a className="text-white" href="#">Home</a></li>
                            <li className="breadcrumb-item"><a className="text-white" href="#">Pages</a></li>
                            <li className="breadcrumb-item text-white active" aria-current="page">About</li>
                        </ol>
                    </nav>
                </div>
            </div>
            {/* Page Header End */}

            {/* About Start */}
            <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
    <div className="container about px-lg-0">
        <div className="row g-0 mx-lg-0">
            <div className="col-lg-6 ps-lg-0" style={{ minHeight: '400px' }}>
                <div className="position-relative h-100">
                    <img className="position-absolute img-fluid w-100 h-100" src={about} style={{ objectFit: 'cover' }} alt="About Us" />
                </div>
            </div>
            <div className="col-lg-6 about-text py-5 wow fadeIn" data-wow-delay="0.5s">
                <div className="p-lg-5 pe-lg-0">
                    <div className="bg-primary mb-3" style={{ width: '60px', height: '2px' }}></div>
                    <h1 className="display-5 mb-4">About Us</h1>
                    <p className="mb-4 pb-2" style={{  textAlign:'justify' }}>
                        Kasturi Group is a high-quality mould making facility, fully integrated with design development and manufacturing of injection and blow moulds. We also manufacture moulded components for agricultural appliances and other industries. 
                        What makes us truly special is our in-depth knowledge and vast experience in the tooling and moulding industry, coupled with our commitment to genuinely partner in our customers' progress with their total satisfaction.
                    </p>
                    <div className="row g-4 mb-4 pb-3">
                        <div className="col-sm-6 wow fadeIn" data-wow-delay="0.1s">
                            <div className="d-flex align-items-center">
                                <div className="btn-square bg-white rounded-circle" style={{ width: '64px', height: '64px' }}>
                                    <img className="img-fluid" src="img/icon/icon-1.png" alt="Icon" />
                                </div>
                                <div className="ms-4">
                                    <h2 className="mb-1" data-toggle="counter-up">45 Years</h2>
                                    <p className="fw-medium text-primary mb-0">Experience</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 wow fadeIn" data-wow-delay="0.3s">
                            <div className="d-flex align-items-center">
                                <div className="btn-square bg-white rounded-circle" style={{ width: '64px', height: '64px' }}>
                                    <img className="img-fluid" src="img/icon/icon-5.png" alt="Icon" />
                                </div>
                                <div className="ms-4">
                                    <h2 className="mb-1" data-toggle="counter-up">200 KM</h2>
                                    <p className="fw-medium text-primary mb-0">Transportation Radius</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a href="contact" className="btn btn-primary rounded-pill py-3 px-5">Explore More</a>
                </div>
            </div>
        </div>
    </div>
</div>
            
            {/* Footer and other components here */}
        </div>
    );
}

export default About;
