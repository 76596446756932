import React from 'react';
import { FaPrint, FaTools, FaCogs, FaMugHot, FaDolly, FaCube, FaBox, FaStar, FaWrench, FaWater, FaPencilRuler, FaBuilding } from 'react-icons/fa';

const iconColors = [
    '#e74c3c', // Red
    '#3498db', // Blue
    '#2ecc71', // Green
    '#f39c12', // Orange
    '#9b59b6', // Purple
    '#e67e22', // Pumpkin
    '#1abc9c', // Turquoise
    '#34495e', // Dark Blue
    '#f1c40f', // Yellow
    '#2c3e50', // Dark Gray
    '#7f8c8d', // Gray
    '#c0392b', // Strong Red
];

function Services() {
    return (
        <div>
            {/* Page Header Start */}
            <div className="container-fluid page-header py-5 mb-5">
                <div className="container py-5">
                    <h1 className="display-3 text-white mb-3 animated slideInDown">Services</h1>
                    <nav aria-label="breadcrumb animated slideInDown">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a className="text-white" href="#">Home</a></li>
                            <li className="breadcrumb-item"><a className="text-white" href="#">Pages</a></li>
                            <li className="breadcrumb-item text-white active" aria-current="page">Services</li>
                        </ol>
                    </nav>
                </div>
            </div>
            {/* Page Header End */}

            {/* Service Start */}
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="text-center">
                        <div className="bg-primary mb-3 mx-auto" style={{ width: '60px', height: '2px' }}></div>
                        <h1 className="display-5 mb-5">Our Services</h1>
                    </div>
                    <div className="row g-0 service-row">
                        <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.1s">
                            <div className="service-item border h-100 p-5">
                                <div className="btn-square bg-light rounded-circle mb-4" style={{ width: '64px', height: '64px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <FaPrint size={32} color={iconColors[0]} />
                                </div>
                                <h4 className="mb-3">Vacuum Casting</h4>
                                <p className="mb-4">High-quality vacuum casting and silicone molding services for prototypes and small batch productions.</p>
                                <a className="btn" href="contact"><i className="fa fa-arrow-right text-white me-3"></i>Know More</a>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.3s">
                            <div className="service-item border h-100 p-5">
                                <div className="btn-square bg-light rounded-circle mb-4" style={{ width: '64px', height: '64px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <FaWrench size={32} color={iconColors[1]} />
                                </div>
                                <h4 className="mb-3">Various Types of Pipe Fitting Moulds</h4>
                                <p className="mb-4">Custom molds for various pipe fittings including UPVC, CPVC, and PVC types.</p>
                                <a className="btn" href="contact"><i className="fa fa-arrow-right text-white me-3"></i>Know More</a>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.5s">
                            <div className="service-item border h-100 p-5">
                                <div className="btn-square bg-light rounded-circle mb-4" style={{ width: '64px', height: '64px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <FaWater size={32} color={iconColors[2]} />
                                </div>
                                <h4 className="mb-3">UPVC Plumbing Fitting Mould</h4>
                                <p className="mb-4">High-quality molds for UPVC plumbing fittings, ensuring durability and precision.</p>
                                <a className="btn" href="contact"><i className="fa fa-arrow-right text-white me-3"></i>Know More</a>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.7s">
                            <div className="service-item border h-100 p-5">
                                <div className="btn-square bg-light rounded-circle mb-4" style={{ width: '64px', height: '64px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <FaWater size={32} color={iconColors[3]} />
                                </div>
                                <h4 className="mb-3">CPVC Plumbing Fitting Mould</h4>
                                <p className="mb-4">Precision molds for CPVC plumbing fittings to meet high industry standards.</p>
                                <a className="btn" href="contact"><i className="fa fa-arrow-right text-white me-3"></i>Know More</a>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.9s">
                            <div className="service-item border h-100 p-5">
                                <div className="btn-square bg-light rounded-circle mb-4" style={{ width: '64px', height: '64px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <FaWater size={32} color={iconColors[4]} />
                                </div>
                                <h4 className="mb-3">PVC Drainages/Sewage Fitting Mould</h4>
                                <p className="mb-4">Durable molds for PVC drainage and sewage fittings, suitable for various applications.</p>
                                <a className="btn" href="contact"><i className="fa fa-arrow-right text-white me-3"></i>Know More</a>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="1.1s">
                            <div className="service-item border h-100 p-5">
                                <div className="btn-square bg-light rounded-circle mb-4" style={{ width: '64px', height: '64px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <FaWater size={32} color={iconColors[5]} />
                                </div>
                                <h4 className="mb-3">PVC Water Supply Fitting Mould</h4>
                                <p className="mb-4">Custom molds for PVC water supply fittings to ensure reliability and performance.</p>
                                <a className="btn" href="contact"><i className="fa fa-arrow-right text-white me-3"></i>Know More</a>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="1.3s">
                            <div className="service-item border h-100 p-5">
                                <div className="btn-square bg-light rounded-circle mb-4" style={{ width: '64px', height: '64px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <FaPrint size={32} color={iconColors[6]} />
                                </div>
                                <h4 className="mb-3">3D Printing</h4>
                                <p className="mb-4">Advanced 3D printing services for accurate, detailed models using various technologies like SLA, SLS, and FDM.</p>
                                <a className="btn" href="contact"><i className="fa fa-arrow-right text-white me-3"></i>Know More</a>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="1.5s">
                            <div className="service-item border h-100 p-5">
                                <div className="btn-square bg-light rounded-circle mb-4" style={{ width: '64px', height: '64px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <FaCogs size={32} color={iconColors[7]} />
                                </div>
                                <h4 className="mb-3">CNC Machining</h4>
                                <p className="mb-4">Custom CNC machined prototypes available in plastics, metals, wood, foam, and more. High precision and quality.</p>
                                <a className="btn" href="contact"><i className="fa fa-arrow-right text-white me-3"></i>Know More</a>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="1.7s">
                            <div className="service-item border h-100 p-5">
                                <div className="btn-square bg-light rounded-circle mb-4" style={{ width: '64px', height: '64px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <FaMugHot size={32} color={iconColors[8]} />
                                </div>
                                <h4 className="mb-3">Soft Tooling/Injection Molding</h4>
                                <p className="mb-4">Cost-effective injection molding and soft tooling solutions for low-volume production and prototyping.</p>
                                <a className="btn" href="contact"><i className="fa fa-arrow-right text-white me-3"></i>Know More</a>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="1.9s">
                            <div className="service-item border h-100 p-5">
                                <div className="btn-square bg-light rounded-circle mb-4" style={{ width: '64px', height: '64px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <FaDolly size={32} color={iconColors[9]} />
                                </div>
                                <h4 className="mb-3">DMLS (Direct Metal Laser Sintering)</h4>
                                <p className="mb-4">Advanced metal additive manufacturing using DMLS technology for functional metal prototypes.</p>
                                <a className="btn" href="contact"><i className="fa fa-arrow-right text-white me-3"></i>Know More</a>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="2.1s">
                            <div className="service-item border h-100 p-5">
                                <div className="btn-square bg-light rounded-circle mb-4" style={{ width: '64px', height: '64px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <FaCube size={32} color={iconColors[10]} />
                                </div>
                                <h4 className="mb-3">FDM (Fused Deposition Modeling)</h4>
                                <p className="mb-4">FDM technology for creating detailed, functional 3D printed models in a wide range of materials.</p>
                                <a className="btn" href="contact"><i className="fa fa-arrow-right text-white me-3"></i>Know More</a>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="2.3s">
                            <div className="service-item border h-100 p-5">
                                <div className="btn-square bg-light rounded-circle mb-4" style={{ width: '64px', height: '64px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <FaBuilding size={32} color={iconColors[11]} />
                                </div>
                                <h4 className="mb-3">PVC Rainwater System Mould</h4>
                                <p className="mb-4">Molds for PVC rainwater systems, designed for efficient collection and drainage.</p>
                                <a className="btn" href="contact"><i className="fa fa-arrow-right text-white me-3"></i>Know More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Service End */}
        </div>
    );
}

export default Services;
