import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import Footer from './Footer';
import Header from './Header';
import Services from './Services';
import Product from './Product';
import Contact from './Contact';
import About from './About';


function App() {
  return (
    <Router>
     <Header/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="service" element={<Services />} />
        <Route path="product" element={<Product />} />
        <Route path="contact" element={<Contact />} />
      
      </Routes>
      <Footer /> 
    </Router>
  );
}

export default App;
